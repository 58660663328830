.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.toggle-label {
  cursor: pointer;
  text-indent: -9999px;
  width: 60px; /* Reduced width */
  height: 30px; /* Reduced height */
  background: #ff4d4d; /* Red background color when not selected */
  display: block;
  border-radius: 15px; /* Adjusted border radius */
  position: relative;
}

.toggle-checkbox:checked + .toggle-label {
  background: #4dbb4d; /* Green background color when selected */
}

.toggle-label:after {
  content: "";
  position: absolute;
  top: 2px; /* Adjusted top position */
  left: 2px; /* Adjusted left position */
  width: 26px; /* Reduced width */
  height: 26px; /* Reduced height */
  background: #fff;
  border-radius: 13px; /* Adjusted border radius */
  transition: 0.3s;
}

.toggle-checkbox:checked + .toggle-label:after {
  left: calc(100% - 2px); /* Adjusted position */
  transform: translateX(-100%);
}
